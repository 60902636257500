import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { Memberships } from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import { DEFAULT_WHITELABEL } from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import * as _ from 'lodash';
import { DealTypes } from '@dollar-flight-club/shared_utilities/lib/models/Deal';
import { map, tap } from 'rxjs/operators';
import { UserModel } from '../models/Authentication';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import contents from '../utilities/strings';
export var UPGRADED_EVENT_TYPE = 'upgraded';
export var PERKS_ORIGIN = 'perks';
export var CONCIERGE_ORIGIN = 'concierge';
export var PREMIUM_PLUS_MEMBERSHIPS = [Memberships.premiumPlus, Memberships.premiumPlusTrial];
export var MessageType;
(function (MessageType) {
    MessageType[MessageType["info"] = 0] = "info";
    MessageType[MessageType["success"] = 1] = "success";
    MessageType[MessageType["error"] = 2] = "error";
})(MessageType || (MessageType = {}));
var Api = /** @class */ (function () {
    function Api(http) {
        var _this = this;
        this.http = http;
        this.error = false;
        this.initialized = false;
        this.loading = true;
        this.message = '';
        this.modalMessage = false;
        this.modalMessageTitle = '';
        this.modalMessageText = '';
        this.modalMessageClass = '';
        this.premium = false;
        this.logged = false;
        this.initializedCallback = [];
        this.confirmUpgrade = false;
        this.confirmFree = false;
        this.membershipId = Memberships.premiumTrial; //v2_annual_plan
        this.dealType = DealTypes.international;
        this.departures = true;
        this.userModel = new BehaviorSubject(null);
        this.alerts = [];
        this.config = environment;
        this.strings = contents.strings;
        setTimeout(function () {
            _this.updateMessageQueue();
        }, 1000);
        this.user$ = this.userModel.asObservable();
        this.user$.subscribe(function (u) { return _this.user = u; });
        this.modalOpen = false;
        this.init();
    }
    Api.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                setTimeout(function () {
                    _this.initialized = true;
                    _this.loading = false;
                    for (var i = 0; i < _this.initializedCallback.length; i++) {
                        _this.initializedCallback[i]();
                    }
                }, 1000);
                return [2 /*return*/];
            });
        });
    };
    Api.prototype.confirmMembershipUpgrade = function (callback) {
        this.confirmUpgradeCallback = callback;
        this.confirmUpgrade = true;
    };
    Api.prototype.confirmMembershipCancellation = function (callback) {
        this.freeCallback = callback;
        this.confirmFree = true;
        gtag('event', 'membership', {
            'event_category': 'unsubscribe',
            'event_label': 'Unsubscribe from members portal',
            'value': 69 //price
        });
    };
    Api.prototype.getWhiteLabelId = function (partnerId, isLifetime) {
        if (partnerId) {
            return partnerId;
        }
        if (isLifetime && !partnerId) {
            return 'lifetimepremiumplus';
        }
        return DEFAULT_WHITELABEL;
    };
    Api.prototype.getDeal = function (id) {
        return this.http.post(this.config.dealInfoUrl, { id: id }, { withCredentials: true }).pipe(map(function (response) { return response.data; }));
    };
    Api.prototype.showModalMessage = function (title, text, type) {
        if (type === void 0) { type = 'success'; }
        this.modalMessage = false;
        this.modalMessageTitle = title;
        this.modalMessageText = text;
        this.modalMessageClass = type;
        this.modalMessage = true;
    };
    Api.prototype.addInitCallback = function (callback) {
        if (!this.initialized)
            this.initializedCallback.push(callback);
        else
            callback();
    };
    /**
     * Set the user via observable so that components can subscribe
     * @param userData logged in user data
     */
    Api.prototype.setUser = function (userData) {
        if (userData.membershipId > 1) {
            this.premium = true;
        }
        this.userModel.next(new UserModel(userData));
        gtag('set', { 'user_id': _.get(this.user, 'id') });
    };
    Api.prototype.checkMembership = function (email) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.http.post(this.config.publicCheckMembership, { email: email }, { withCredentials: true }).toPromise()];
                    case 1:
                        res = _a.sent();
                        this.setUser(res);
                        return [2 /*return*/, res];
                    case 2:
                        error_1 = _a.sent();
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Api.prototype.signup = function (email, password, options) {
        //this.partner_id = 'blah';//TODO: Set from returned user object
        return this.http.post(this.config.apiSignupUrl, {
            email: email,
            password: password,
            partner_id: options.partnerId || DEFAULT_WHITELABEL,
            source: options.source,
            medium: options.medium,
            rfsn: options.rfsn
        }, { withCredentials: true });
    };
    Api.prototype.updateAccountInfo = function (user) {
        var _this = this;
        return this.http.post(this.config.updateAccountUrl, user, { withCredentials: true }).toPromise().then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var responseInfo;
            return tslib_1.__generator(this, function (_a) {
                responseInfo = res;
                if (!responseInfo)
                    return [2 /*return*/, false];
                else {
                    this.user.phone = responseInfo.phone;
                    this.setUser(responseInfo);
                    return [2 /*return*/, true];
                }
                return [2 /*return*/];
            });
        }); }).catch(function (error) {
            return false;
        });
    };
    Api.prototype.newsLetterSignup = function (newsletters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.http.post(this.config.newsLetterSignupUrl, newsletters, { withCredentials: true }).toPromise().then(function (res) {
                                return true;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        error_2 = _a.sent();
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Api.prototype.toggleNotifications = function (enable) {
        var _this = this;
        var body = { type: 'email' };
        var url = enable ? this.config.activateNotificationsUrl : this.config.deactivateNotificationsUrl;
        return this.http.post(url, body, { withCredentials: true }).pipe(tap(function () {
            _this.user.emailNotifications = enable;
        }));
    };
    Api.prototype.updateRegion = function (region) {
        var _this = this;
        return this.http.post(this.config.updateRegionUrl, { region: region }, { withCredentials: true }).toPromise().then(function (res) { return _this.user.region = region; }).catch(function (error) {
        });
    };
    Api.prototype.login = function (credentials) {
        return this.http.post(this.config.membersLoginUrl, credentials, { withCredentials: true });
    };
    Api.prototype.googleAuthLogin = function (credentials) {
        return this.http.post(this.config.googleLogin, credentials, { withCredentials: true });
    };
    Api.prototype.googleAuthSignUp = function (credentials) {
        return this.http.post(this.config.googleSignup, credentials, { withCredentials: true });
    };
    Api.prototype.hashLogin = function (hash) {
        var _this = this;
        return this.http.post(this.config.hashLoginUrl, { hash: hash }, { withCredentials: true })
            .toPromise().then(function (res) {
            var responseInfo = res;
            if (responseInfo.error) {
                return false;
            }
            else {
                _this.setUser(responseInfo.data);
                _this.logged = true;
                return true;
            }
        }).catch(function (error) {
            _this.logged = false;
            return false;
        });
    };
    Api.prototype.recover = function (email) {
        return this.http.post(this.config.membersRecoverUrl, { email: email }, { withCredentials: true })
            .toPromise().then(function (res) {
            return true;
        }).catch(function (error) {
            return false;
        });
    };
    Api.prototype.validateUserToken = function (userToken) {
        return this.http.post(this.config.resetTokenValidationUrl, {
            link: userToken,
        }, { withCredentials: true })
            .toPromise().then(function (res) {
            var responseInfo = res;
            return !responseInfo.error;
        }).catch(function (error) {
            return false;
        });
    };
    Api.prototype.unsubscribe = function () {
        return this.http.post(this.config.membershipCancelationUrl, {}, { withCredentials: true }).toPromise().then(function (res) {
            var responseInfo = res;
            return !responseInfo.error;
        }).catch(function (error) {
            return false;
        });
    };
    /**
     *Subscribe is for net new customers with token
     */
    Api.prototype.subscribe = function (coupon, membershipId) {
        if (membershipId === void 0) { membershipId = this.membershipId; }
        return this.http.post(this.config.membershipSubscriptionUrl, {
            membership: membershipId,
            coupon: coupon
        }, { withCredentials: true })
            .toPromise().then(function (res) {
            var responseInfo = res;
            return responseInfo;
        }).catch(function (error) {
            return false;
        });
    };
    Api.prototype.setupIntents = function (user) {
        return this.http.post(this.config.setupIntentUrl, { user: user }, { withCredentials: true }).toPromise().then(function (res) {
            var responseInfo = res;
            return responseInfo;
        });
    };
    /**
     *Upgrade is for users that are upgrading to a new stripe plan
     */
    Api.prototype.upgrade = function (user, selectedPlanId, whitelabel, coupon, paymentIntentId, trialCode, paymentMethod, lifetime, location, updateBilling, utm_source, utm_medium, trafficSource) {
        if (trialCode === void 0) { trialCode = null; }
        return this.http.post(this.config.membershipUpgradeUrl, {
            user: user,
            selectedPlanId: selectedPlanId,
            whitelabel: whitelabel,
            coupon: coupon,
            paymentIntentId: paymentIntentId,
            trialCode: trialCode,
            paymentMethod: paymentMethod,
            lifetime: lifetime,
            location: location,
            updateBilling: updateBilling,
            utm_source: utm_source,
            utm_medium: utm_medium,
            trafficSource: trafficSource
        }, { withCredentials: true }).toPromise().then(function (res) {
            var responseInfo = res;
            return responseInfo;
        }).catch(function (error) {
            return error;
        });
    };
    Api.prototype.applyCoupon = function (coupon) {
        return this.http.post(this.config.membershipCouponUrl, {
            membership: this.membershipId, coupon: coupon
        }, { withCredentials: true });
    };
    Api.prototype.pause = function () {
        return this.http.post(this.config.membershipPause, {}, { withCredentials: true });
    };
    Api.prototype.extendTrial = function () {
        return this.http.post(this.config.extendTrialUrl, {}, { withCredentials: true });
    };
    Api.prototype.membershipInfo = function () {
        return this.http.post(this.config.membershipInfoUrl, {}, { withCredentials: true }).toPromise().then(function (res) {
            var responseInfo = res;
            if (responseInfo.error) {
                return null;
            }
            else {
                return responseInfo;
            }
        }).catch(function (error) {
            return false;
        });
    };
    Api.prototype.resetPassword = function (newPassword, userToken) {
        return this.http.post(this.config.passwordResetUrl, { link: userToken, password: newPassword }, { withCredentials: true }).toPromise().then(function (res) {
            var responseInfo = res;
            return !responseInfo.error;
        }).catch(function (error) {
            return false;
        });
    };
    Api.prototype.logout = function () {
        var _this = this;
        this.showPageLoader();
        return this.http.post(this.config.membersLogoutUrl, {}, { withCredentials: true }).toPromise().then(function (res) {
            _this.logged = false;
            document.location.href = _this.config.public_area;
        }).catch(function (error) {
            _this.logged = false;
            document.location.href = _this.config.public_area;
        });
    };
    Api.prototype.getFavoriteDestinations = function () {
        return this.http.post(this.config.favoriteDestinationsListUrl, {}, { withCredentials: true }).toPromise().then(function (res) {
            var responseInfo = res;
            if (responseInfo.error)
                return [];
            return responseInfo;
        }).catch(function (error) {
            return [];
        });
    };
    Api.prototype.getFavoriteAirlines = function () {
        return this.http.post(this.config.favoriteAirlinesListUrl, {}, { withCredentials: true }).toPromise().then(function (res) {
            var responseInfo = res;
            if (responseInfo.error)
                return [];
            return responseInfo.data.list;
        }).catch(function (error) {
            return [];
        });
    };
    Api.prototype.getFavoriteDepartures = function () {
        var _this = this;
        return this.http.post(this.config.favoriteDeparturesListUrl, {}, { withCredentials: true }).toPromise().then(function (res) {
            var responseInfo = res;
            if (responseInfo.error) {
                _this.departures = false;
                return [];
            }
            if (responseInfo.length === 0) {
                _this.departures = false;
                return [];
            }
            return responseInfo;
        }).catch(function (error) {
            return [];
        });
    };
    Api.prototype.findAirports = function (search) {
        return this.http.post(this.config.apiAirportsSearchUrl, {
            search: search,
            count: 5,
        }, { withCredentials: true }).pipe(
        //TODO: Remove this once we change endpoint res to just airport[]
        map(function (res) { return res.data.list; }));
    };
    Api.prototype.findDestinations = function (search) {
        return this.http.post(this.config.apiSearchDestinations, {
            query: search,
            results: 5,
        }, { withCredentials: true }).pipe(
        //TODO: Remove this once we change endpoint res to just destination[]
        map(function (res) { return res.data; }));
    };
    //TODO: Replace current endpoint used in the dashboard to set departure airport with this
    Api.prototype.setDepartureAirport = function (selectedAirport) {
        return this.http.post(this.config.favoriteDeparturesAddUrl, {
            airports: [selectedAirport.ID]
        }, { withCredentials: true });
    };
    //TODO: Replace current endpoint used in the dashboard to set destination airport with this
    Api.prototype.setDestination = function (destinationId) {
        return this.http.post(this.config.favoriteDestinationsAddUrl, {
            id: destinationId
        }, { withCredentials: true });
    };
    //TODO: Replace current endpoint used in the dashboard to remove dream destination with this
    Api.prototype.removeDreamDestination = function (id) {
        return this.http.post(this.config.favoriteDestinationsRemoveUrl, {
            id: id
        }, { withCredentials: true });
    };
    Api.prototype.addFavoriteDestination = function (id) {
        return this.http.post(this.config.favoriteDestinationsAddUrl, { id: id }, { withCredentials: true }).toPromise().then(function (res) {
            var responseInfo = res;
            if (responseInfo.error)
                return false;
            return true;
        }).catch(function (error) {
            return false;
        });
    };
    Api.prototype.addFavoriteAirline = function (id) {
        return this.http.post(this.config.favoriteAirlinesAddUrl, { id: id }, { withCredentials: true }).toPromise().then(function (res) {
            var responseInfo = res;
            if (responseInfo.error)
                return false;
            return responseInfo.data;
        }).catch(function (error) {
            return false;
        });
    };
    Api.prototype.addFavoriteDeparture = function (id) {
        return this.http.post(this.config.favoriteDeparturesAddUrl, { id: id }, { withCredentials: true }).toPromise().then(function (res) {
            var responseInfo = res;
            if (responseInfo.error)
                return false;
            return true;
        }).catch(function (error) {
            return false;
        });
    };
    Api.prototype.removeFavoriteDestination = function (id) {
        return this.http.post(this.config.favoriteDestinationsRemoveUrl, { id: id }, { withCredentials: true }).toPromise().then(function (res) {
            {
                var responseInfo = res;
                if (responseInfo.error)
                    return false;
                return true;
            }
        }).catch(function (error) {
            return false;
        });
    };
    Api.prototype.removeFavoriteAirline = function (id) {
        return this.http.post(this.config.favoriteAirlinesRemoveUrl, { id: id }, { withCredentials: true }).toPromise().then(function (res) {
            {
                var responseInfo = res;
                if (responseInfo.error)
                    return false;
                return true;
            }
        }).catch(function (error) {
            return false;
        });
    };
    Api.prototype.removeFavoriteDeparture = function (id) {
        return this.http.post(this.config.favoriteDeparturesRemoveUrl, { id: id }, { withCredentials: true }).toPromise().then(function (res) {
            {
                var responseInfo = res;
                if (responseInfo.error)
                    return false;
                return true;
            }
        }).catch(function (error) {
            return false;
        });
    };
    Api.prototype.getLatestDeals = function (type) {
        if (type === void 0) { type = 0; }
        if (this.config) {
            return this.http.post(this.config.latestDealsUrl, {
                count: 21,
                type: type
            }, { withCredentials: true }).toPromise().then(function (res) {
                var responseInfo = res;
                return responseInfo.data.list;
            }).catch(function (error) {
                return [];
            });
        }
    };
    Api.prototype.getDealsList = function (page) {
        return this.http.post(this.config.dealsListUrl, {
            page: page,
            size: 24,
            type: this.dealType
        }, { withCredentials: true }).toPromise().then(function (res) {
            var responseInfo = res;
            if (responseInfo.error)
                return false;
            return responseInfo.data;
        }).catch(function (error) {
            return false;
        });
    };
    Api.prototype.checkCoupon = function (coupon) {
        return this.http.post(this.config.checkCouponUrl, { coupon: coupon });
    };
    Api.prototype.getPlanPrice = function (id) {
        return this.http.get(this.config.getPlanPriceUrl, { params: { planId: id } }).pipe(map(function (responseInfo) { return responseInfo.price; }));
    };
    Api.prototype.getInvoicePrice = function (plan) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(this.config.getInvoicePriceUrl, { plan: plan }, { withCredentials: true }).toPromise()];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res.unit_amount / 100];
                }
            });
        });
    };
    Api.prototype.travelProductCheckout = function (email, partnerId, coupon, source, medium) {
        return this.http.post(this.config.createCheckoutSession, { email: email, partnerId: partnerId, coupon: coupon, utmSource: source, utmMedium: medium }, { responseType: "text" });
    };
    Api.prototype.perkProductCheckout = function (email, source, medium, partnerId, coupon) {
        return this.http.post(this.config.createPerkCheckoutSession, { email: email, utmSource: source, utmMedium: medium, partnerId: partnerId, coupon: coupon }, { responseType: "text" });
    };
    Api.prototype.showPageLoader = function (message) {
        if (message === void 0) { message = ''; }
        this.message = message.length ? message : 'Please wait';
        this.loading = true;
    };
    Api.prototype.hidePageLoader = function () {
        this.message = '';
        this.loading = false;
    };
    Api.prototype.scrollTop = function () {
        window.scrollTo(0, 0);
    };
    Api.prototype.showMessage = function (type, message, duration) {
        if (duration === void 0) { duration = 10; }
        var alert = {
            type: type,
            message: message,
            duration: duration,
            start: new Date(),
            class: 'success',
            icon: 'fa fa-check'
        };
        switch (type) {
            case MessageType.error:
                alert.class = 'error';
                alert.icon = 'fa fa-warning';
                break;
            case MessageType.info:
                alert.class = 'info';
                alert.icon = 'fa fa-info-circle';
                break;
        }
        this.alerts.push(alert);
    };
    Api.prototype.updateMessageQueue = function () {
        var _this = this;
        var currentTime = Date.now();
        if (this.alerts) {
            for (var i = 0; i < this.alerts.length; i++) {
                if ((currentTime - this.alerts[i].start.getTime()) / 1000 >= this.alerts[i].duration)
                    this.alerts.splice(i, 1);
            }
        }
        setTimeout(function () {
            _this.updateMessageQueue();
        }, 1000);
    };
    Api.prototype.removeAlert = function (index) {
        if (index < this.alerts.length)
            this.alerts.splice(index, 1);
    };
    Api.prototype.updateCartId = function (id, cartId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(this.config.updateCartIdUrl, { userId: id, cartId: cartId }, { withCredentials: true }).toPromise()];
                    case 1:
                        response = _a.sent();
                        if (response.error) {
                            return [2 /*return*/];
                        }
                        return [2 /*return*/, response];
                }
            });
        });
    };
    Api.prototype.setReferral = function (id, eventType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(this.config.sendReferralUrl, { id: id, eventType: eventType }, { withCredentials: true }).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Api.prototype.genUniqueId = function () {
        var dateStr = Date
            .now()
            .toString(36);
        var randomStr = Math
            .random()
            .toString(36)
            .substring(2, 8);
        return dateStr + "-" + randomStr;
    };
    Api.prototype.getCurrentUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.post(environment.baseUrl + "/dfcmembers/session", {}, { withCredentials: true }).pipe(tap(function (user) {
                        _this.userModel.next(user);
                    })).toPromise()];
            });
        });
    };
    Api.prototype.getNearestAirports = function (lat, lon) {
        var params = new HttpParams();
        if (lat !== undefined && lat !== null) {
            params = params.set('lat', lat.toString());
        }
        if (lon !== undefined && lon !== null) {
            params = params.set('lon', lon.toString());
        }
        return this.http.get(this.config.getNearestAirportsUrl, { params: params, withCredentials: true });
    };
    Api.prototype.getPopularDestinations = function () {
        return this.http.get(this.config.findPopularDestinationsUrl, { withCredentials: true });
    };
    Api.prototype.getIPLocation = function () {
        return new Promise(function (resolve, reject) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) { return resolve(position); }, function (error) { return reject(error); });
            }
            else {
                reject(new Error('Geolocation is not supported by this browser.'));
            }
        });
    };
    Api.prototype.getCurrencyExchangeRate = function (currency) {
        var params = { currency: currency };
        return this.http.get(this.config.getCurrencyUrl, { params: params, withCredentials: true });
    };
    Api.prototype.getAllPerks = function () {
        return this.http.get(this.config.getAllPerksUrl, { withCredentials: true });
    };
    Api.prototype.getPerk = function (perkName) {
        return this.http.get(this.config.getPerkUrl, { params: { name: perkName }, withCredentials: true });
    };
    Api.prototype.getUserPerkProductSubscription = function (product) {
        var params = { product: product };
        return this.http.get(this.config.getUserPerkProductSubscriptionUrl, { params: params, withCredentials: true });
    };
    return Api;
}());
export { Api };
export var TrialCodes;
(function (TrialCodes) {
    TrialCodes["TwoWeekTrial"] = "p2WkZisyJ";
    TrialCodes["OneMonthTrial"] = "p1AkZisyJ";
    TrialCodes["ThreeMonthTrial"] = "p3oUmcydb";
    TrialCodes["SixMonthTrial"] = "p6TbprZPt";
})(TrialCodes || (TrialCodes = {}));
