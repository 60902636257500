import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Api, MessageType, TrialCodes, UPGRADED_EVENT_TYPE} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
  Membership,
  MembershipName,
  Memberships,
  MembershipSpec
} from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import {
  DEFAULT_WHITELABEL,
  findWhiteLabel,
  IWhiteLabel
} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import {environment} from '../../../environments/environment';
import {UserModel} from '../../models/Authentication';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

declare var Stripe: any;
declare var gtag: Function;
declare var r: any;

enum AvailableCurrencies {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  CAD = 'CAD',
  AUD = 'AUD'
}

const currencyLocales: { [key in AvailableCurrencies]: string } = {
  [AvailableCurrencies.USD]: 'en-US',
  [AvailableCurrencies.EUR]: 'de-DE',
  [AvailableCurrencies.GBP]: 'en-GB',
  [AvailableCurrencies.CAD]: 'en-CA',
  [AvailableCurrencies.AUD]: 'en-AU'
};

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements AfterViewInit {
  @ViewChild('linkauth') linkAuth: ElementRef;
  @ViewChild('paymentelement') paymentEl: ElementRef;
  @Input() onboardingRedirect = false;
  @Input() updateBilling = false;
  @Input() user: UserModel;
  @Input() lifetime = false;
  @Input() coupon: string;
  @Input() rfsn = false;
  @Input() partnerId: string;
  @Input() trialCode: TrialCodes;
  @Input() location: string;
  @Input() utm_source: string;
  @Input() utm_medium: string;
  @Input() trafficSource: string;
  @Input() forcePremiumPlus = false;


  whitelabel_config: IWhiteLabel;
  card: any;
  error: string;
  showPromo = false;
  loading = false;
  discount: string;
  stripe: any;
  elements: any;
  myMembership: MembershipSpec;
  memberships: typeof Memberships;
  membershipNames: typeof MembershipName = MembershipName;
  selectedPlan: MembershipSpec;
  selectedPlanId: Memberships;
  alreadyPremium = false;
  logo: string;
  benefits: any;
  couponError: string;
  validationErr: string;
  isFree = false;

  planPrice: string;
  amountToBeCharged: string;
  selectedCurrency: string = AvailableCurrencies.USD;
  yearlyPriceDisplay: string;
  availableCurrencies: AvailableCurrencies[] = [
    AvailableCurrencies.USD,
    AvailableCurrencies.EUR,
    AvailableCurrencies.GBP,
    AvailableCurrencies.CAD,
    AvailableCurrencies.AUD
  ];

  PlanBenefits = {
    save: 'Save 90% on every flight, every year',
    domesticDeals: 'Domestic & international flight deals',
    flightDealsPPlus: 'Economy and business-class flight deals',
    fly: 'Fly to Hawaii from $149 or anywhere on your list',
    partnerPerks: 'Exclusive partner perks & discounts',
    cancelAnytime: 'Cancel anytime, no questions asked'
  };


  constructor(private api: Api,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    if (this.partnerId) {
      this.whitelabel_config = findWhiteLabel(this.partnerId);
    } else if (this.user.partnerId) {
      this.whitelabel_config = findWhiteLabel(this.user.partnerId);
    } else {
      this.whitelabel_config = findWhiteLabel(DEFAULT_WHITELABEL);
    }
    const envStatus = environment.production  ? 'production_plans' : 'development_plans';
    if(this.whitelabel_config.stripe[envStatus].premium_plus.invoice_item){
      this.lifetime = true;
    }
    this.myMembership = Membership.getMembershipSpec(this.user.membershipId);
    this.api.addInitCallback(async () => {
      this.stripe = Stripe(this.api.config.stripe_key);
      this.updateSelectedPlan(this.myMembership.key).then(async () => {
          if (this.coupon) {
            this.checkCoupon();
          }
        await this.updateCurrency();
      });
    });
  }
  async ngAfterViewInit() {
    if (this.user && this.selectedPlanId && this.whitelabel_config) {
      if (this.linkAuth && this.paymentEl) {
        await this.setupElements();
      } else {
        console.error('linkAuth or paymentEl is null');
      }
    }
  }

  public async updateSelectedPlan(currentPlan: number) {
    if (this.lifetime || (this.coupon && this.whitelabel_config.signup.premiumPlus_offer) ||
      (currentPlan > Memberships.free && !this.whitelabel_config.signup.premiumPlus_offer)) {
      this.selectedPlanId = Memberships.premiumPlus;
    } else if (this.whitelabel_config.signup.premiumPlus_offer || this.forcePremiumPlus) {
      this.selectedPlanId = Memberships.premiumPlusTrial;
    } else if (this.coupon) {
      this.selectedPlanId = Memberships.premium;
    } else {
      this.selectedPlanId = Memberships.premiumTrial;
    }
    this.selectedPlan = Membership.getMembershipSpec(this.selectedPlanId);

    this.getLogo(Membership.getMembershipName(this.selectedPlanId));
  }

  async setMembershipPrice(selectedPlanId: number): Promise<number> {
    const nonTrialPlanId = Membership.getNonTrialPlan(selectedPlanId);
    const membershipName = Membership.getMembershipName(nonTrialPlanId);
    const envStatus = environment.production ? 'production_plans' : 'development_plans';
    const plans = this.whitelabel_config.stripe[envStatus];
    try {
      if (this.lifetime) {
        return await this.api.getInvoicePrice(plans[membershipName].invoice_item);
      }
      return await this.api.getPlanPrice(plans[membershipName].no_trial).toPromise();
    } catch (error) {
      this.error = 'There has been an error, please try again. If the problem persists contact support@dollarflightclub.com'
      console.error('Error setting membership price:', error);
      return Promise.reject(error);
    }
  }

  async updateCurrency() {
    const selectedLocale = currencyLocales[this.selectedCurrency as AvailableCurrencies];
      const membershipPrice = await this.setMembershipPrice(this.selectedPlanId)
    if (this.selectedCurrency === AvailableCurrencies.USD) {
      this.planPrice = this.formatCurrency(selectedLocale, membershipPrice);
      return this.updateAmountToBeCharged(this.lifetime ? membershipPrice : 1);
    }
    this.api.getCurrencyExchangeRate(this.selectedCurrency).pipe(
      tap(async response => {
        const rate = parseFloat((membershipPrice * response.rate).toFixed(2));
        this.planPrice = this.formatCurrency(selectedLocale, rate);
        return this.updateAmountToBeCharged(this.lifetime ? rate : response.rate);

      }),
      catchError(error => {
        console.error('Error fetching currency exchange rate:', error);
        return of(undefined);
      })
    ).subscribe();
  }

  updateAmountToBeCharged(price: number) {
    const selectedLocale = currencyLocales[this.selectedCurrency as AvailableCurrencies];
    this.amountToBeCharged = this.formatCurrency(selectedLocale, price);
    const pricePattern = /(\$\d+(\.\d+)?)/;
    this.yearlyPriceDisplay = this.whitelabel_config.signup.yearly_price.replace(pricePattern, this.amountToBeCharged);
  }

  formatCurrency(locale: string, amount: number): string {
    const options: Intl.NumberFormatOptions = { style: 'currency', currency: this.selectedCurrency };
    if (this.selectedCurrency === AvailableCurrencies.USD) {
      options.minimumFractionDigits = 0;
      options.maximumFractionDigits = 0;
    }
    return new Intl.NumberFormat(locale, options).format(amount);
  }

  checkCoupon(): void {
    if (!this.coupon) {
      this.setCouponError('Please enter a valid promo code.');
      return;
    }
    this.api.checkCoupon(this.coupon).pipe(
      tap(response => {
        if (response.error) {
          this.setCouponError(response.message);
        } else if (!response.valid) {
          this.setCouponError('Expired Coupon');
        } else {
          this.applyValidCoupon(response);
        }
      }),
      catchError(error => {
        console.error('Error checking coupon:', error);
        this.setCouponError(error.error.message);
        return of(undefined);
      })
    ).subscribe();
  }

  private setCouponError(message: string): void {
    this.couponError = message;
    this.coupon = '';
    this.discount = '';
  }

  private applyValidCoupon(response: any): void {
    this.couponError = '';
    this.discount = response.percent_off ? `${response.percent_off}%` : `${response.amount_off / 100}`;

    let planPriceWithoutSymbol = this.planPrice.replace(/[\$\€\£\s]/g, '');
    planPriceWithoutSymbol = planPriceWithoutSymbol.replace(',', '.');
    const planPriceNumber = parseFloat(planPriceWithoutSymbol);

    if (this.selectedCurrency === 'USD') {
      const newPrice = response.percent_off
        ? (planPriceNumber - (planPriceNumber * response.percent_off / 100))
        : (planPriceNumber - (response.amount_off / 100))
      return this.updateAmountToBeCharged(parseFloat(String(newPrice)));
    }
      this.api.getCurrencyExchangeRate(this.selectedCurrency).pipe(
        tap(currencyResponse => {
          const rate = currencyResponse.rate;
          const couponAmountInSelectedCurrency = response.amount_off / 100 * rate;
          const newPrice = response.percent_off
            ? (planPriceNumber - (planPriceNumber * response.percent_off / 100))
            : (planPriceNumber - couponAmountInSelectedCurrency)
          this.updateAmountToBeCharged(parseFloat(String(newPrice)));
        }),
        catchError(error => {
          console.error('Error fetching currency exchange rate:', error);
          return of(undefined);
        })
      ).subscribe();
    this.showPromo = false;
  }

  async onSubmit() {
    try {
      this.loading = true;
      try {
        //Track conversion with Refersion
        if (this.api.user.cartId && this.activatedRoute.snapshot.params.partner_id === 'financebuzztrial') {
          r.addTrans({
            'order_id': this.api.genUniqueId(),
            'currency_code': 'USD',
            'cart_id': this.user.cartId
          });

          r.addCustomer({
            'email': this.user.email,
          });

          r.addItems({
            'sku': 'TRIAL_SIGN_UP',
            'quantity': 1,
            'price': 0
          });
          r.sendConversion();
        }
      } catch (e) {
        console.warn(e);
      }

      if (gtag) {
        gtag('event', 'membership', {
          'event_category': 'upgrade',
          'event_label': 'Upgrade from basic in members portal',
          'value': this.planPrice
        });
      }

      await this.stripe.confirmSetup({
        elements: this.elements,
        confirmParams: {},
        redirect: 'if_required'
      }).then(async (res) => {
        if(res.error && res.error.type === 'validation_error'){
          this.validationErr = res.error.message;
          this.loading = false;
          return;
        }
        if (res.setupIntent.status === 'succeeded') {
          if (res.error) {
            this.error = res.error.message;
            this.loading = false;
            return;
          }
          if (!this.lifetime && this.rfsn) {
            const rfsn = {
              cart: this.user.stripeCustomerId,
              id: localStorage.getItem('rfsn_v4_id'),
              url: window.location.href,
              aid: localStorage.getItem('rfsn_v4_aid'),
              cs: localStorage.getItem('rfsn_v4_cs')
            };
            r.sendCheckoutEvent(rfsn.cart, rfsn.id, rfsn.url, rfsn.aid, rfsn.cs);
            await this.api.setReferral(this.user.id, UPGRADED_EVENT_TYPE);
          }
          const response = await this.api.upgrade(this.user, this.selectedPlanId, this.whitelabel_config, this.coupon, res.setupIntent.id, this.trialCode, res.setupIntent.payment_method, this.lifetime, this.location, this.updateBilling, this.utm_source, this.utm_medium, this.trafficSource);
          if(response.error){
            this.loading = false;
            this.error = 'We could not process your payment. Please try again. If you continue to have issues, please contact support@dollarflightclub.com';
            return;
          }
          this.loading = false;
          this.modalService.dismissAll();
          if (this.updateBilling) {
            window.location.href = '/profile/account';
            return;
          }
          if (this.onboardingRedirect){
            return this.router.navigate(['/onboarding'], {
              queryParams: {
                onboard: true,
                plan: this.selectedPlan.basePlan
              }
            });
          }
           window.location.href = '/dashboard'
        }
      });
    } catch (err) {
      console.log(err);
      this.error = 'Unable to process payment information. Please try again.';
      this.loading = false;
      this.api.showMessage(MessageType.error, 'Unable to process payment information.');
    }
  }
  async tryAgain(){
    this.modalService.dismissAll();
  }

  getTrialPeriod() {
    if(this.whitelabel_config.signup.yearly_price === 'Try for $1'){
      return '14-day';
    } else {
      return '';
    }
  }

  modalInfo(): any {
    if (this.trialCode) {
      return {
        modalTitle: `<p class="plan-price"> Try for ${this.amountToBeCharged} | Cancel Anytime | <br> ${this.planPrice}/year after ${this.trialCodeToDays(this.trialCode)} trial</p>`,
        buttonCTA: `Try for ${this.amountToBeCharged}`
      };
    } else if (this.whitelabel_config.id !== DEFAULT_WHITELABEL && !this.lifetime && !this.coupon) {
      return {
        modalTitle: `<p class="plan-price">${this.yearlyPriceDisplay} | Cancel Anytime | ${this.planPrice}/year after ${this.getTrialPeriod()} trial</p>`,
        buttonCTA: `Try For ${this.amountToBeCharged}`
      };
    } else if (this.lifetime) {
      return {
        modalTitle: `<p class="plan-price">One Payment for Lifetime Access | Save 93% Today </p>`,
        buttonCTA: `Pay ${this.amountToBeCharged}`
      };
    } else if (this.user.membershipId === Memberships.free && !this.coupon && !this.updateBilling && !this.whitelabel_config.signup.premiumPlus_offer && !this.lifetime) {
      return {
        modalTitle: `<p class="plan-price">${this.yearlyPriceDisplay} | Cancel Anytime | ${this.planPrice}/year after 14-day trial</p>`,
        buttonCTA: `${this.yearlyPriceDisplay}`
      };
    } else if (!this.updateBilling && this.coupon) {
      return {
        modalTitle: `<p class="plan-price">Only ${this.amountToBeCharged} for first year | Cancel Anytime | ${this.planPrice}/year after</p>`,
        buttonCTA: `Pay ${this.amountToBeCharged}`
      };
    } else if (!this.updateBilling) {
      return {
        modalTitle: `<p class="plan-price">Only ${this.planPrice}/year | Cancel Anytime</p>`,
        buttonCTA: `Pay ${this.planPrice}`
      };
    } else {
      return {
        modalTitle: '<h3>Update Your Payment Details<h3>',
        buttonCTA: 'Update Card'
      };
    }
  }
  trialCodeToDays(trialCode: TrialCodes): string {
    if (!trialCode) {
      return '';
    }
    if(trialCode === TrialCodes.TwoWeekTrial){
      return '14 day';
    }
    if (trialCode === TrialCodes.OneMonthTrial) {
      return '30 day';
    }
    if (trialCode === TrialCodes.ThreeMonthTrial) {
      return '3 month';
    }
    if (trialCode === TrialCodes.SixMonthTrial) {
      return '6 month';
    }
  }

  getLogo(plan: string) {
    this.logo = this.whitelabel_config.logos[plan];
    this.getBenefits();
    if (this.updateBilling) {
      this.logo = 'https://dfcapp.s3.amazonaws.com/Dollar-Flight-Club-Logo-Original-2.png';
    }
  }

  getBenefits() {
    if (this.lifetime) {
      this.benefits = [
        this.PlanBenefits.save,
        this.PlanBenefits.fly,
        this.PlanBenefits.domesticDeals,
        this.PlanBenefits.flightDealsPPlus
      ];
    } else if (this.selectedPlanId === Memberships.premium || this.selectedPlanId === Memberships.premiumTrial) {
      this.benefits = [
        this.PlanBenefits.save,
        this.PlanBenefits.fly,
        this.PlanBenefits.domesticDeals,
        this.PlanBenefits.cancelAnytime
      ];
    } else if (this.selectedPlanId === Memberships.premiumPlus || this.selectedPlanId === Memberships.premiumPlusTrial) {
      this.benefits = [
        this.PlanBenefits.save,
        this.PlanBenefits.fly,
        this.PlanBenefits.domesticDeals,
        this.PlanBenefits.flightDealsPPlus,
        this.PlanBenefits.partnerPerks,
        this.PlanBenefits.cancelAnytime
      ];
    }
  }

  private async setupElements() {
    try {
        const response = await this.api.setupIntents(this.user);
        const appearance = {
          theme: 'stripe',
          labels: 'floating',
          variables: {
            colorPrimary: '#0570de',
            colorBackground: '#ffffff',
            colorText: '#30313d',
            colorDanger: '#df1b41',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '2px',
            borderRadius: '4px',
          }
        };

        this.user = response.user;
        const clientSecret = response.setupIntent.client_secret;

        this.elements = await this.stripe.elements({appearance, clientSecret});
        const linkAuthenticationElement = this.elements.create('linkAuthentication');
        linkAuthenticationElement.mount(this.linkAuth.nativeElement);
        linkAuthenticationElement.value = this.user.email;
        linkAuthenticationElement.on('change', (event) => {
          this.api.userEmail = event.value.email;
        });

        const paymentElementOptions = {
          layout: 'tabs',
          defaultValues: {
            billingDetails:
              {email: this.user.email}
          },
          terms: {
            card: 'never'
          }
        };
        const paymentElement = this.elements.create('payment', paymentElementOptions);
        paymentElement.mount(this.paymentEl.nativeElement);
    } catch (err) {
      console.log(err);
    }
  }

}

